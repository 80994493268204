<template>
  <div class="home">
    <div class="first">
      <img class="first-back" src="../../assets/may-day/qingtong.jpg" alt=""  v-if="level==1"/>
      <img class="first-back" src="../../assets/may-day/baiying.webp" alt=""  v-if="level==2"/>
      <img class="first-back" src="../../assets/may-day/huangjin.jpeg" alt=""  v-if="level==3"/>
      <img class="first-back" src="../../assets/may-day/bojin.jpeg" alt=""  v-if="level==4"/>
      <img class="first-back" src="../../assets/may-day/zuanshi.jpeg" alt=""  v-if="level==5"/>
      <img class="first-back" src="../../assets/may-day/xingyao.webp" alt=""  v-if="level==6"/>
      <img class="first-back" src="../../assets/may-day/wangzhe.webp" alt=""  v-if="level==7"/>
      <img class="first-img0" src="../../assets/may-day/icon-12.png" alt="" />

      <img class="first-img1" src="../../assets/may-day/icon-13.png" alt="" />
    </div>



    <div class="second">
      <img class="second-back" src="../../assets/may-day/icon-9.png" alt="" />
      <img class="second-icon0" src="../../assets/may-day/icon-0.png" alt="" />
      <img class="second-icon1" src="../../assets/may-day/icon-8.png" alt="" />
      <img class="second-icon2" src="../../assets/may-day/icon-level1.png" alt=""  v-if="level==1" />
      <img class="second-icon2" src="../../assets/may-day/icon-level2.png" alt=""  v-if="level==2" />
      <img class="second-icon2" src="../../assets/may-day/icon-level3.png" alt=""  v-if="level==3" />
      <img class="second-icon2" src="../../assets/may-day/icon-level4.png" alt=""  v-if="level==4" />
      <img class="second-icon2" src="../../assets/may-day/icon-level5.png" alt=""  v-if="level==5" />
      <img class="second-icon2" src="../../assets/may-day/icon-level6.png" alt=""  v-if="level==6" />
      <img class="second-icon2" src="../../assets/may-day/icon-level7.png" alt=""  v-if="level==7" />
      <div class="second-icon3">
        <div class="icon-3-list" v-for="(item, index) in 3" :key="index">
          {{ index == 0 ? "排名" : index == 1 ? "小组名" : "积分" }}
        </div>
      </div>
      <div class="second-icon4">
        <img class="icon4-back" src="../../assets/may-day/icon-3.png" alt="" />
        <div class="icon4-div">
          <div
            class="div-list"
            v-for="(item, index) in InviteList"
            :key="index"
          >
            <div class="list-left">
              <img
                v-if="index == 0"
                src="../../assets/may-day/icon-6.png"
                alt=""
              />

              <img  v-if="index == 1" src="../../assets/may-day/icon-4.png" alt="">

              <img  v-if="index == 2" src="../../assets/may-day/icon-5.png" alt="">


              <div v-else>{{ index != 0 && index != 1 && index != 2? index+1: '' }}</div>

            </div>
            <div class="list-center">{{ item.teamName }}</div>
            <div class="list-right">{{ item.integral }}</div>
          </div>
        </div>
      </div>

      <div class="second-icon5">
        <img
          class="icon5-header"
          src="../../assets/may-day/icon-71.png"
          alt=""
        />
        <img class="icon5-back" src="../../assets/may-day/icon-8.png" alt="" />

        <div class="icon5-div">
          <div class="div-list" v-for="(item, index) in 7" :key="index">
            {{
              index == 0
                ? "积分0-20青铜段位"
                : index == 1
                ? "积分20-40白银段位"
                : index == 2
                ? "积分40-60黄金段位段位"
                : index == 3
                ? "积分60-85铂金段位段位"
                : index == 4
                ? "积分85-110钻石段位段位"
                : index == 5
                ? "积分110-145星耀段位"
                : "积分>=145传奇王者段位"
            }}
          </div>
        </div>

        <img
          class="icon5-award"
          src="../../assets/may-day/icon-11.png"
          alt=""
        />
      </div>

      <div class="second-icon6">
        <div>升段小组请当天找李老师转动神秘转盘:</div>
        <div>转盘可产生礼物如下：</div>
        <div>礼品类型:贴纸、胸针、盲盒、卡片、捏捏乐、拼图、本子</div>
        <div>零食类型：辣条、糖等</div>
        <div>体验类型：VR风景英文解说</div>
      </div>
      <div class="second-icon7">
        <div class="icon7-title">
          <button class="link pretty-button" @click="search('')">总积分</button>
          <button class="link pretty-button" @click="search('2001')">2001</button>
            <button class="link pretty-button" @click="search('2002')">2002</button>
            <button class="link pretty-button" @click="search('2003')">2003</button>
              <button class="link pretty-button" @click="search('2004')">2004</button>
                <button class="link pretty-button" @click="search('2104')">2104</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getmayDayInvitenum,getFileAccessHttpUrl } from "../../api/activity";

export default {
  data() {
    return {
      InviteList: [],
      level:"",
      url:"temp/bg_dl_1727507645262.png",
    };
  },
  created() {
  /*  const now = new Date();
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    if(hours<11)
    {
      this.$router.push({
        path: '/notice',
      });
    }
    else if(hours==11&&minutes<=50)
    {
      this.$router.push({
        path: '/notice',
      })
    }
    else if(hours==12&&minutes>40)
    {
      this.$router.push({
        path: '/notice',
      })
    }
    else if(hours>12&&hours<15)
    {
      this.$router.push({
        path: '/notice',
      })
    }*/
    this.level=this.$route.query.level;
    this.getmayDayInvitenumMet();
  },
  methods: {
    // 请求数据
    getmayDayInvitenumMet() {
      let params = {
        column: "integral",
        order: "desc",
        pageNo: "1",
        pageSize: "10",
        level:this.$route.query.level
      };

      getmayDayInvitenum(params).then((res) => {
        if (res.code == 200) {
          this.url=res.result.records[0].teamUrl;
          if(this.url==null)
          {
            this.url="temp/bg_dl_1727507645262.png"
          }
          this.InviteList = res.result.records;
          console.log("请求数据成功！", this.InviteList);
        }
      });
    },
    /* 图片预览 */
    search(classesNo)
    {
      let params = {
        column: "integral",
        order: "desc",
        pageNo: "1",
        pageSize: "10",
        classesNo:classesNo,
        level:this.level
      };

      getmayDayInvitenum(params).then((res) => {
        if (res.code == 200) {
          this.InviteList = res.result.records;
          console.log("请求数据成功！", this.InviteList);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.home {
  position: relative;

  .first {
    width: 100%;
    position: relative;
    display: flex;

    .first-back {
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 1;
    }

    .first-img0 {
      position: absolute;
      z-index: 2;
      top: 9.714vw;
      right: 19.14vw;
      width: 13.71vw;
      height: 13.71vw;
    }

    .first-img1 {
      position: absolute;
      z-index: 2;
      left: 2.28vw;
      top: 7.714vw;
      width: 13.71vw;
      height: 13.71vw;
    }
  }

  .second {
    min-height: 22vw;
    width: 100%;
    margin-top: -16vw;
    position: relative;

    .second-back {
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }

    .second-icon0 {
      width: 96%;
      position: absolute;
      top: 3.5vw;
      left: 0;
      z-index: 1;
      margin: 0 2%;
    }

    .second-icon1 {
      position: absolute;
      width: 94%;
      margin: 0 2.5% 0 3.5%;
      left: 0;
      top: 5vw;
      z-index: 1;
      height: 120vw;
    }

    .second-icon2 {
      position: absolute;
      width: 60%;
      z-index: 2;
      left: 0;
      top: -1.15vw;
      margin: 0 20%;
    }

    .second-icon3 {
      position: absolute;
      width: 84%;
      z-index: 2;
      left: 0;
      top: 12.6vw;
      margin: 0 8%;
      background: #9afbb6;
      height: 9.15vw;
      border-radius: 3.42vw;
      display: flex;
      align-items: center;

      .icon-3-list {
        font-size: 3.2vw;
        font-weight: 900;
        text-align: center;
        color: #353636;
      }

      .icon-3-list:nth-child(1) {
        width: 30%;
      }

      .icon-3-list:nth-child(2) {
        width: 40%;
        text-align: left;
      }

      .icon-3-list:nth-child(3) {
        width: 30%;
      }
    }

    .second-icon4 {
      position: absolute;
      width: 84%;
      z-index: 2;
      left: 0;
      top: 23vw;
      margin: 0 8%;

      .icon4-back {
        width: 100%;
        height: 96vw;
        position: absolute;
        left: 0;
      }

      .icon4-div {
        width: 100%;
        position: relative;

        .div-list {
          width: 96%;
          margin-top: 1.4vw;
          height: 7.74vw;
          margin-left: 1.4%;
          display: flex;
          align-items: center;
          background: #ddffe7;
          border-radius: 1.15vw;
          font-weight: 800;
          font-size: 3.2vw;

          .list-left {
            width: 30%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              width: 7.74vw;
              height: 7vw;
            }
          }

          .list-center {
            width: 40%;
            text-align: left;
          }

          .list-right {
            width: 30%;
            text-align: center;
          }
        }

        .div-list:first-child {
          margin-top: 2.51vw;
        }
      }
    }

    .second-icon5 {
      position: absolute;
      height: 113vw;
      top: 130vw;
      left: 0;

      .icon5-header {
        position: absolute;
        width: 60%;
        z-index: 2;
        left: 0;
        top: -1.15vw;
        margin: 0 20%;
      }

      .icon5-back {
        position: relative;
        width: 94%;
        margin: 0 2.5% 0 3.5%;
        left: 0;
        top: 4.58vw;
        z-index: 1;
        height: 108vw;
      }

      .icon5-div {
        position: absolute;
        z-index: 2;
        top: 13.8vw;
        width: 84%;
        margin: 0 8%;

        .div-list {
          background: #9bfbb7;
          margin-bottom: 3.42vw;
          border-radius: 2.74vw;
          padding: 2.4vw 3.57vw;
          box-sizing: border-box;
          font-size: 3.5vw;
          font-weight: 800;
          text-align: left;
        }
      }

      .icon5-award {
        position: absolute;
        width: 17.14vw;
        right: 0;
        bottom: 0;
        z-index: 2;
      }
    }

    .second-icon6 {
      font-size: 3.2vw;
      font-weight: 800;
      position: absolute;
      text-align: left;
      width: 90%;
      top: 250.14vw;
      margin: 0 5%;

      div {
        margin-bottom: 1.14vw;
      }
    }

    .second-icon7 {
      font-size: 3.2vw;
      font-weight: 800;
      position: absolute;
      text-align: center;
      bottom: 9.14vw;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      top: 305vw;
    }
  }
  .link
  {

    color: red;
  }
  .pretty-button {
    background-color: #4CAF50; /* 按钮颜色 */
    border: none;
    color: white; /* 文字颜色 */
    text-align: center; /* 文字居中 */
    text-decoration: none;
    display: inline-block;
    font-size: 16px; /* 字体大小 */
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 16px; /* 圆角边框 */
    transition: background-color 0.3s; /* 动画过渡效果 */
  }

  .pretty-button:hover {
    background-color: #45a049; /* 鼠标悬浮颜色 */
  }
}
</style>
