import axios from 'axios'
import qs from 'qs'
import router from '@/router'


const service = axios.create({
    baseURL: 'https://after.dwhk918.com/jeecg-boot',
    timeout: 60000,
})

service.defaults.transformRequest = data => qs.stringify(data);

service.interceptors.request.use(config => {
    if (localStorage.getItem('token')) {
        config.headers.Authorization = localStorage.getItem('token')
        config.headers.accessToken = localStorage.getItem('token')
    }

    return config

}, (error) => Promise.reject(error))

service.interceptors.response.use(response => {

    const res = response.data
    // 这里是接口处理的一个示范，可以根据自己的项目需求更改


    // 如果接口正常，直接返回数据
    return res
}, (error) => {

    console.log('获取接口返回', error.response.status)


    if (error.response.status == 500) {
        window.prompt("服务器正在维修，请稍后","确认");
    }


    if (error.name == 'Error') {

    } else {

    }
    return Promise.reject(error)
})

export default service
