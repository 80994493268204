import request from './request'


// 五一活动
export function getmayDayInvitenum(params) {
    return request.get(`/student/team/list`, { params })
}


export function getFileAccessHttpUrl(avatar,subStr) {

    if (!subStr) subStr = 'http'
    try {
        if (avatar && avatar.startsWith(subStr)) {
            return avatar;
        } else {
            if (avatar && avatar.length > 0 && avatar.indexOf('[') == -1) {
                return "https://after.dwhk918.com/jeecg-boot/sys/common/static" + "/" + avatar;
            }
        }
    } catch (err) {
        return;
    }
}
